import { Page, Text, WixDesignSystemProvider, Box, Heading } from '@wix/design-system'
import '@wix/design-system/styles.global.css';
import * as Icons from '@wix/wix-ui-icons-common';

function Loading() {
    return (
        <WixDesignSystemProvider features={{ newColorsBranding: true }}>
            <Page height='100vh'>
                <Page.Content>
                    <Box height={"90vh"} direction="vertical" verticalAlign="middle">
                        <Box align="center" direction="vertical">
                            <Icons.StatusComplete size="54" />
                            <Heading appearance="H3">請稍候</Heading>
                            <Text size="medium">我們正在重定向至 Wix.com</Text>
                        </Box>
                    </Box>
                </Page.Content>
            </Page></WixDesignSystemProvider>
    )
}

export default Loading;