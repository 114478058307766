import { FormField, Input, Text, Card, ToggleSwitch, Box, Page, Button, Breadcrumbs, Layout, Cell, WixDesignSystemProvider } from '@wix/design-system'
import '@wix/design-system/styles.global.css';
import { To, useNavigate } from "react-router-dom";
import React from 'react';

function Settings() {
    let navigate = useNavigate();
    const routeChange = (path: To) => {
        navigate(path);
    }
    const useRenderListItem = ({ title, subtitle, padding, toggleChecked }: { title: string, subtitle: string, padding: string, toggleChecked: boolean }) => {
        const [checked, setChecked] = React.useState(toggleChecked);

        return (
            <Box verticalAlign="middle" align="space-between" padding={padding}>
                <Box direction="vertical">
                    <Text weight="normal">{title}</Text>
                    <Text secondary size="small">
                        {subtitle}
                    </Text>
                </Box>
                <ToggleSwitch checked={checked} onChange={() => setChecked(!checked)} />
            </Box>
        );
    };
    return (
        <WixDesignSystemProvider features={{ newColorsBranding: true }}>
            <Page>
                <Page.Header
                    actionsBar={
                        <Box gap="SP2">
                            <Button skin="inverted" onClick={() => { routeChange('/') }}>取消</Button>
                            <Button>儲存</Button>
                        </Box>
                    }
                    breadcrumbs={
                        <Breadcrumbs
                            activeId="2"
                            items={[
                                { id: '1', value: '發票' },
                                { id: '2', value: '應用設定' },
                            ]}
                            onClick={(item) => {
                                if (item.id === '1') {
                                    routeChange('/')
                                }
                            }}
                        />
                    }
                    showBackButton
                    onBackClicked={() => { }}
                    title='應用設定'
                />

                <Page.Content>
                    <Layout>
                        <Cell span={8}>
                            <Card>
                                <Card.Header title='綠界商家帳號' subtitle='連線至綠界電子發票平台，以建立電子發票' />
                                <Card.Divider />
                                <Card.Content>
                                    <Box direction='vertical' gap="SP2">
                                        <FormField label="綠界商家代號" infoContent="格式為七位數數字">
                                            <Input clearButton />
                                        </FormField>
                                        <FormField label="HashKey">
                                            <Input clearButton />
                                        </FormField>
                                        <FormField label="HashIV">
                                            <Input clearButton />
                                        </FormField></Box>
                                </Card.Content>
                            </Card>
                        </Cell>
                        <Cell span={8}>
                            <Card>
                                <Card.Header title='自動化' subtitle='通過自動化，您可以設定電子發票的發送時間，以及發票的開立方式。（更多功能即將到來）' />
                                <Card.Divider />
                                <Card.Content>
                                    <Box direction="vertical" >
                                        {useRenderListItem({
                                            title: '自動建立發票',
                                            subtitle: '當交易狀態已更新為「APPROVED（已核准）」時，自動建立發票。',
                                            padding: '0px 0px 18px',
                                            toggleChecked: false,
                                        })} </Box>
                                </Card.Content>
                            </Card>
                        </Cell>
                        <Cell>
                            <Page.Footer divider>
                                <Page.Footer.End>
                                    <Box gap="SP2">
                                        <Button priority="secondary" onClick={() => { routeChange('/') }}>取消</Button>
                                        <Button>儲存</Button>
                                    </Box>
                                </Page.Footer.End>
                            </Page.Footer>
                        </Cell>
                    </Layout>
                </Page.Content>
            </Page>
        </WixDesignSystemProvider>
    )
}

export default Settings;
