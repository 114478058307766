import Settings from './Settings'
import GettingStarted from './GettingStarted'
import Home from './Home'
import Loading from './Loading'
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {

  const token = new URLSearchParams(window.location.search).get('token');

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=7363d59c-3549-41bf-b6fe-96e0f9c0676b&redirectUrl=https://certifiedcode.editorx.io/ecpay-shipments/_functions/auth`;
    return <Loading/>;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="settings" element={<Settings />} />
        <Route path="getting-started" element={<GettingStarted />} />
        <Route path="/" element={<Home />}>
          <Route index element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;
