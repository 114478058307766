const requestMap = {
    MerchantID: "特店編號",
    RelateNumber: "特店自訂編號",
    CustomerID: "客戶編號",
    CustomerIdentifier: "統一編號",
    CustomerName: "客戶名稱",
    CustomerAddr: "客戶地址",
    CustomerPhone: "客戶手機號碼",
    CustomerEmail: "客戶電子信箱",
    ClearanceMark: "通關方式",
    Print: "列印註記",
    Donation: "捐贈註記",
    LoveCode: "捐贈碼",
    CarruerType: "載具類別",
    CarruerNum: "載具編號",
    TaxType: "課稅類別",
    SpecialTaxType: "特種稅額類別",
    SalesAmount: "發票總金額(含稅)",
    InvoiceRemark: "發票備註",
    Items: "商品",
    ItemSeq: "商品序號",
    ItemName: "商品名稱",
    ItemCount: "商品數量",
    ItemWord: "商品單位",
    ItemPrice: "商品單價",
    ItemTaxType: "商品課稅別",
    ItemAmount: "商品合計",
    ItemRemark: "商品備註",
    InvType: "字軌類別",
    vat: "商品單價是否含稅",
    TimeStamp: "時間戳印",
    CheckMacValue: "驗證值"
}

const responseMap = {
    RtnCode: "回應代碼",
    RtnMsg: "回應訊息",
    InvoiceNo: "發票號碼",
    InvoiceDate: "發票開立時間",
    RandomNumber: "隨機碼",
    TimeStamp: "時間戳印",
    CheckMacValue: "驗證值",
    InvoiceNumber: "發票編號"
}

export { requestMap, responseMap }