import { MarketingPageLayout, MarketingPageLayoutContent, Card, Image, MessageModalLayout, Button, Text, Layout, Cell, Box, Tooltip, IconButton, WixDesignSystemProvider, Modal } from '@wix/design-system'
import '@wix/design-system/styles.global.css';
import { ConfirmSmall } from '@wix/wix-ui-icons-common';
import { To, useNavigate, useLocation } from "react-router-dom";
import React from 'react';

function GettingStarted() {
    const { search } = useLocation();
    let navigate = useNavigate();
    const routeChange = (path: To) => {
        navigate(path);
    }
    const [shownBeforeSetupModal, setShownBeforeSetupModal] = React.useState(false);

    return (
        <WixDesignSystemProvider features={{ newColorsBranding: true }}>
            <Card>
                <MarketingPageLayout
                    removeImageHorizontalPadding
                    removeImageVerticalPadding
                    content={
                        <Box height="100%" verticalAlign="middle">
                            <MarketingPageLayoutContent
                                title="自動化您的運單開立工作"
                                content="通過「綠界科技 ECPay 物流」應用，使您的運單建立工作高度自動化，讓您可以專注於經營您的業務。"
                                actions={<Button onClick={() => { setShownBeforeSetupModal(true) }}>開始使用</Button>}
                            />
                        </Box>
                    }
                    image={<Image height="100%" borderRadius={0} src="https://www.wix-style-react.com/storybook/MarketingIllustration3.png" />}
                />
            </Card>;
            <Modal
                isOpen={shownBeforeSetupModal}>
                <MessageModalLayout
                    onCloseButtonClick={() => { setShownBeforeSetupModal(false) }}
                    primaryButtonText="我已準備好綠界金鑰"
                    secondaryButtonText="取消"
                    title="提示"
                    primaryButtonProps={{ prefixIcon: <ConfirmSmall /> }}
                    content={<Box direction="vertical" gap="SP4"><Text>要使用此應用，您必須先有有效的綠界帳戶。要確認帳戶是否有效，請根據下方影片查看「物流」服務的金鑰是否存在。</Text>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/sheIdwubD7E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></Box>
                    }
                    footnote={
                        <Text size="tiny">開始使用即代表您同意<a href='https://www.certifiedcode.us/legal/terms-of-services' target={"_blank"}>認證代碼的「使用條款」</a> 及 <a href='https://www.ecpay.com.tw/About/ProvisionOnMember' target={"_blank"}>綠界科技 ECPay 的「會員服務條款」</a></Text>
                    }
                    primaryButtonOnClick={() => { routeChange(`/${search}&fromSetup=true`) }}
                    secondaryButtonOnClick={() => { setShownBeforeSetupModal(false) }}
                />
            </Modal>
        </WixDesignSystemProvider>
    )
}

export default GettingStarted;
